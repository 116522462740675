<template>
  <div class="wrapper__profile-main">
    <TitleSection uppercase big title="Mi Cuenta" />

    <v-card>
      <v-card-text class="py-10 px-10">
        <v-row>
          <v-col cols="12" xs="12" md="12" lg="10" offset-lg="1">
            <v-form class="text-right">
              <div class="wrapper__form-fields">
              <v-row>
                <v-col cols="12" xs="12" sm="12" lg="6">
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">Nombre</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">Apellido</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">Email</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col xs="12" sm="12" lg="6">
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">País</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">Contraseña</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="3">
                      <label for="">Repetir contraseña</label>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" lg="9">
                      <FormTextField label="" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              </div>
              <div class="wrapper__form-action">
              <v-row>
                <v-col>
                  <v-btn color="primary"> Guardar </v-btn>
                </v-col>
              </v-row>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TitleSection from "@/components/general/TitleSection";
import FormTextField from '@/components/forms/TextField';

import { ref } from "@vue/composition-api";

export default {
  components: {
    TitleSection,
    FormTextField
  },
  setup() {

    return {};
  },
};
</script>


<style lang="scss" scoped>
.wrapper__profile-main {
  .wrapper__form-fields, .wrapper__form-action {
    padding: 5rem 0;
  }
}
</style>